import React from 'react';
import { Redirect, useLocation } from '@reach/router';
import styled from '@emotion/styled';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { Link } from 'gatsby';

const linkButtonProps = {
  as: Link,
  to: '/?auth=1'
};

const statuses = ['success', 'fail'];

const RegisterConfirm = () => {
  const location = useLocation();
  const status = new URLSearchParams(location.search).get('status');
  if (!status || !statuses.includes(status)) {
    return <Redirect to="/" replace />;
  }
  return (
    <StyledRegisterConfirm>
      <div className="status-icon">
        <Icon name={status === 'success' ? 'checkmark' : 'cross'} />
      </div>
      <p className="text">
        {status === 'success'
          ? 'Your email is successfully confirmed'
          : 'Something went wrong. Please try again'}
      </p>
      {status === 'success' ? (
        <Button primary {...linkButtonProps}>
          Sign In
        </Button>
      ) : (
        <Button primary {...{ ...linkButtonProps, to: '/' }}>
          Go Back
        </Button>
      )}
    </StyledRegisterConfirm>
  );
};

export default RegisterConfirm;

const StyledRegisterConfirm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 0;
  height: 300px;

  .status-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 50%;

    .icon {
      fill: ${props => props.theme.colors.primary};
    }
  }

  .text {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 48px;
  }

  .button {
    height: 36px;
    width: 100%;
    max-width: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
`;
